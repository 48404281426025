import React from "react";

import selfPortrait from "../images/selfPortrait_sky60.svg";
import css from "./hiItsMeHeader.module.scss";

export function HiItsMeHeader() {
  return (
    <div className={css.wrapper}>
      <img
        alt="Cartoon man working on a computer"
        className={css.selfPortrait}
        src={selfPortrait}
        title="I work best with a coffee and...a potted lettuce leaf?"
      />

      <h1 className={css.name}>Trevor Farlow</h1>
    </div>
  );
}

export default HiItsMeHeader;
